import React from 'react'
import {createInertiaApp} from '@inertiajs/react'
//import {hydrateRoot} from 'react-dom/client'
import { createRoot } from 'react-dom/client'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers'
import NProgress from 'nprogress'
import { router } from '@inertiajs/react'
import gtmInit from "./Mixins/GoogleTagManager";
/*import route from "ziggy-js";
import {Ziggy} from "./ziggy";*/
//globalThis.route = (name, params, absolute, config = Ziggy) => route(name, params, absolute, config);
globalThis.ssr = false;
globalThis.localMemory = {};
router.on('start', () => {
  //console.log('start')
  NProgress.start()
})

router.on('finish', () => {
  console.log('finish')
  NProgress.done()
})

createInertiaApp({
  id: 'app',
  progress: {
    delay: 250,
    color: '#10796E',
    includeCSS: true,
    showSpinner: false,
  },
  resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
  setup({el, App, props}) {
    //hydrateRoot(el, <App {...props} />)
    createRoot(el).render(<App {...props} />)
  },
}).then(()=>{
  setTimeout(function() {
    gtmInit();
    window.YandexRotorSettings.IsLoaded = true;
  }, 100);
})